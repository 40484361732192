<template>
	<v-row class="border-light-grey mx-0 py-2" style="position: relative">
		<v-progress-linear
			v-if="loading"
			color="orange darken-2"
			indeterminate
			height="4"
			class="file-upload-loader"
		></v-progress-linear>
		<v-col
			md="3"
			v-for="(row, index) in files"
			class="text-center h-100px py-3 border-right-light-grey"
			:key="index"
			:class="{ 'border-top-light-grey': index > 3 }"
		>
			<v-hover v-slot="{ hover }">
				<v-tooltip top content-class="custom-top-tooltip">
					<template v-slot:activator="{ on, attrs }">
						<span class="mime-icon" v-bind="attrs" v-on="on">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL(`media/mime/${getMime(row)}.svg`)" />
							<!--end::Svg Icon-->
						</span>
						<v-btn
							class="file-upload-delete"
							v-on:click="removeAttachment(index)"
							icon
							depressed
							small
							color="red lighten-1"
							><v-icon small>mdi-delete</v-icon></v-btn
						>
						<v-btn
							class="file-upload-download"
							v-on:click="downloadFile(row)"
							icon
							depressed
							small
							color="blue darken-4"
							><v-icon small>mdi-download</v-icon></v-btn
						>
					</template>
					<span>{{ row.name }}</span>
				</v-tooltip>
			</v-hover>
		</v-col>
		<v-col md="3" class="h-100px py-3" :class="{ 'border-top-light-grey': files.length }">
			<div
				v-on:click="selectFile()"
				class="cursor-pointer text-center px-3 py-5 dashed-border-semi-bold-grey"
			>
				<v-icon>mdi-progress-upload</v-icon><br />
				Choose File
			</div>
			<v-file-input class="d-none" ref="uploadFile" v-on:change="uploadFile($event)"></v-file-input>
		</v-col>
	</v-row>
</template>

<script>
import { round, toNumber, head, split, last } from "lodash";
import { SET_ERROR } from "@/core/services/store/common.module";
import { UploadFiles } from "@/core/lib/upload.lib";

export default {
	props: {
		dbFiles: {
			type: Array,
			default() {
				return new Array();
			},
		},
	},
	data() {
		return {
			loading: false,
			files: [],
		};
	},
	watch: {
		files: {
			deep: true,
			handler(param) {
				this.$emit("files", param);
			},
		},
	},
	methods: {
		downloadFile(file) {
			window.open(file.url, "_blank");
		},
		getMime(file) {
			const path_array = split(file.path, ".");
			return last(path_array);
		},
		selectFile() {
			this.$refs["uploadFile"].$refs["input"].click();
		},
		removeAttachment(index) {
			let fileArr = this.files;
			fileArr.splice(index, 1);
			this.files = fileArr;
		},
		uploadFile(param) {
			if (!param) {
				return false;
			}
			const limit = toNumber(this.$uploadSizeLIMIT);
			const file_size = round(param.size / 1024, 2);
			if (file_size > limit) {
				const show_limit = round(limit / 1024, 2);
				this.$store.commit(SET_ERROR, [
					{ model: true, message: `Maximum Allowed Size: ${show_limit}MB` },
				]);
				this.$refs["uploadFile"].reset();
			} else {
				this.loading = true;
				const file = new FormData();
				file.append("files[0]", param);
				UploadFiles(file)
					.then((response) => {
						console.log(response);
						const first = head(response);
						this.files.push(first);
					})
					.catch((error) => {
						this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
	},
};
</script>
