<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/asset-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				Assets
			</v-flex>
			<v-flex class="text-right" v-if="!noAdd && relationType != 'project'">
				<!-- <v-btn v-on:click="getListing()" color="blue darken-4 text-white" class="mx-2" depressed tile
					><v-icon small left>mdi-reload</v-icon> Reload</v-btn
				> -->
				<v-btn v-on:click="newAsset" color="blue darken-4 text-white" class="ml-2" depressed tile
					><v-icon small left>mdi-playlist-plus</v-icon>New Asset</v-btn
				>
			</v-flex>
		</v-layout>
		<Table internal :page-loading="pageLoading" type="asset" detail-route="asset-detail"></Table>
	</div>
</template>
<script>
import Table from "@/view/components/Table";
import ListingMixin from "@/core/mixins/listing.mixin";

export default {
	name: "asset-internal-listing",
	title: "Listing Internal Asset",
	mixins: [ListingMixin],
	components: {
		Table,
	},
	data() {
		return {
			pageTitle: "Assets",
			endpoint: "asset",
			internal: true,
			relation_type: null,
			relation_type_id: 0,
		};
	},
	props: {
		relationType: {
			type: String,
			default: null,
		},
		relationTypeId: {
			type: Number,
			default: 0,
		},
		noAdd: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		init() {
			this.relation_type = this.relationType;
			this.relation_type_id = this.relationTypeId;
		},
		newAsset() {
			let query = { t: new Date().getTime() };
			if (this.relationType == "supplier") {
				query = { t: new Date().getTime(), supplier: this.relation_type_id };
			}

			if (this.relationType == "group") {
				query = { t: new Date().getTime(), group: this.relation_type_id };
			}

			this.$router.push({
				name: "asset-create",
				query: query,
			});
		},
	},
	beforeMount() {
		this.init();
	},
};
</script>
